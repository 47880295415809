import {axiosInstance} from "../../../config/axiosInstance";
import {CONSTANTS} from "../../../constants/constants";

export const BOOKING_API = {
  // getBookingDetails: async ({bookingId, categoryId}) => (
  //   await axiosInstance.get(`${CONSTANTS?.API?.API_VERSION?.V1}/booking/${bookingId}/${categoryId}`)
  // ),
  getDetailsById: async ({currency, bookingId, categoryId}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/booking/detailById`,
      {params: {currency, bookingId, categoryId},
    })
  ),
  getBookings: async ({type, filterType, currency}) => (
    await axiosInstance.get(
      `${CONSTANTS?.API?.API_VERSION?.V1}/booking`,
      { params: { type, filterType, currency } }
    )
  ),
  addBookingProperty: async ({
    property_id,
    checkIn,
    checkOut,
    members,
    currency,
    modeOfPayment,
    cryptoCurrency,
    couponCode,
    bookingFor,
    bookingForDob,
    bookingForEmail,
    bookingForName,
    bookingForSurname,
    }) => (
    await axiosInstance.post(`${CONSTANTS?.API?.API_VERSION?.V1}/booking/property/booking`, {
      property_id,
      checkIn,
      checkOut,
      members,
      currency,
      modeOfPayment,
      cryptoCurrency,
      couponCode,
      bookingFor,
      bookingForDob,
      bookingForEmail,
      bookingForName,
      bookingForSurname,
    })
  ),
  retryPropertyPayment: async ({bookingId, modeOfPayment, cryptoCurrency}) => (
    await axiosInstance.post(`${CONSTANTS?.API?.API_VERSION?.V1}/booking/property/booking/paymentRetry`, {
      bookingId, modeOfPayment, cryptoCurrency
    })
  ),
  addBookingCar: async ({
      car_id, pickupDate, dropDate, driver, members, bookingFor, bookingForName, modeOfPayment, currency, couponCode
    }) => (
    await axiosInstance.post(`${CONSTANTS?.API?.API_VERSION?.V1}/booking/car/booking`, {
      car_id, pickupDate, dropDate, driver, members, bookingFor, bookingForName, modeOfPayment, currency, couponCode
    })
  ),
};