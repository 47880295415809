export const smile = () => {
  const blue = 'color: #3c5b6e';
  const orange = 'color: #e79732';
  const whiteBold = 'color: white; Cambay-weight: bold; Cambay-size: 16px;';

console.log(`
%c████████    %c██          ██
%c██     ██   %c ██        ██ 
%c██      ██  %c  ██      ██  
%c██     ██   %c   ██    ██   
%c████████    %c    ██  ██    
%c██     ██   %c     ████     
%c██      ██  %c      ██  

%cWelcome to RentVip!
%cWe are excited to have you here :)
%cLet's grow together and achieve great things!
`,
    blue, orange,
    blue, orange,
    blue, orange,
    blue, orange,
    blue, orange,
    blue, orange,
    blue, orange,
    whiteBold,
    whiteBold,
    whiteBold,
  );
}
