import {useEffect} from 'react';
import {smile} from "./utils/smile";
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {setResize} from "./redux/slices/layoutSlice";
import {resetUser} from "./redux/slices/userSlice";
import {CONSTANTS} from "./constants/constants";
import {
  getUserThunk,
  initializeCountryThunk,
  initializeCurrencyThunk,
  initializeLanguageThunk,
  setCurrencyThunk,
  setLanguageThunk,
} from "./redux/thunks/userThunk";
import {localStorageService} from "./services/localStorageService";

export const useAppInitialization = () => {

  useEffect(() => smile(), []); //smile

  const dispatch = useDispatch();

  const {isAuth, country, currency, language} = useSelector(state => state?.user, shallowEqual);
  const {activeModals} = useSelector(state => state?.layout, shallowEqual);

  //modal
  useEffect(() => {
    if (activeModals?.length > 0) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [activeModals]);

  //auth
  useEffect(() => {
    if (isAuth) {
      dispatch(getUserThunk());
    } else {
      dispatch(resetUser());
    }
  }, [isAuth, dispatch]);

  //COUNTRY
  useEffect(() => {
    if (!country) {
      dispatch(initializeCountryThunk());
    }
  }, [dispatch, country]);

  //LANGUAGES
  useEffect(() => {
    if (!!country && !language) {
      const languageTypeSave = localStorageService?.get(CONSTANTS?.VALUE?.LANGUAGE) ?? null;
      const languageSave = !!languageTypeSave
        ? Object.values(CONSTANTS?.CONFIG?.LANGUAGES?.AVAILABLE).find((cur) => cur?.TYPE === languageTypeSave)
        : false

      if (languageSave) {
        dispatch(setLanguageThunk({language: languageSave}));
      } else {
        dispatch(initializeLanguageThunk({country}));
      }
    }
  }, [dispatch, country, language]);

  //CURRENCY
  useEffect(() => {
    if (!!country && !currency) {
      const currencyTypeSave = localStorageService?.get(CONSTANTS?.VALUE?.CURRENCY) ?? null;
      const currencySave = !!currencyTypeSave
        ? Object.values(CONSTANTS?.CONFIG?.CURRENCY?.AVAILABLE).find((cur) => cur?.TYPE === currencyTypeSave)
        : false

      if (!!currencySave) {
        dispatch(setCurrencyThunk({currency: currencySave}));
      } else {
        dispatch(initializeCurrencyThunk({country}));
      }
    }
  }, [dispatch, currency, country]);

  //windowWidth
  useEffect(() => {
    const handleResize = () => {
      dispatch(setResize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      }));
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);
};