export const VALUE_CONSTANTS = {
  RENTAL_TYPES: {
    PROPERTY: 'PROPERTY',
    CAR: 'CAR',
    TOUR: 'TOUR',
  },

  AUTH_SECTIONS: {
    PHONE_NUMBER: 'PHONE_NUMBER',
    OTP_CODE: 'OTP_CODE',
    REGISTRATION: 'REGISTRATION',
  },

  POSITION_ICON_BTN: {
    START: "START",
    END: "END",
  },


  ACCESS_TOKEN: 'accessToken-v1',
  COUNTRY: 'country-v1',
  CURRENCY: 'currency-v1',
  LANGUAGE: 'language-v1',
  UTC_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  URL_FORMAT: 'DD-MM-YYYY',
  DATE_OF_BIRTH_FORMAT: "DD.MM.YYYY",

  // AUTH: {
  //   LAST_RESEND_TIME: 'lastResendTime-v1'
  // }
};